/* eslint-disable react/jsx-handler-names */
"use client";
import PropTypes from "prop-types";
import CategoryImage from "../../../components/molecules/categoryImage";
import useAnalytics from "../../../analytics/useAnalytics";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../../analytics/clevertapConstants";

/**
 * DoubleDeckerList component displays a carousel of categories
 * with a double-decker layout on smaller screens.
 *
 * @component
 * @param {Object} props - The component props
 * @param {Object} props.categoryDoubleDeckerItems - The data for the category items
 * @param {Array} props.categoryDoubleDeckerItems.list - The list of category items
 * @returns {JSX.Element}
 */
function DoubleDeckerList({
  categoryDoubleDeckerItems,
  classes,
  dimensions = { width: 176, height: 176 },
  isMobile,
  showOnDesktop = 8,
}) {
  const half = Math.ceil(categoryDoubleDeckerItems.length / 2);
  const firstHalf = categoryDoubleDeckerItems.slice(0, !isMobile ? showOnDesktop : half);
  const secondHalf = categoryDoubleDeckerItems.slice(half);

  const { trackEvent } = useAnalytics();
  const pageBannerData = {
    banner_id: null,
    page_name: cleverTapCommonWords.HOME,
    page_type: cleverTapCommonWords.HOME,
  };

  return (
    <>
      {!isMobile && firstHalf.length > 0 ? (
        <div className={`-mt-20 px-16 sm:px-40 ${classes}`}>
          <ul className="flex w-full justify-between gap-16 xll:gap-32">
            {firstHalf.map((item, index) => {
              return (
                <li
                  key={item.category_link}
                  className="xml:max-w-[140px] xmll:max-w-[160px] md:max-w-[110px] lg:max-w-[125px] xl:max-w-[130px] xll:max-w-[190px]"
                >
                  <a
                    className="category_new_UX"
                    href={item.category_link}
                    onClick={() => {
                      trackEvent(
                        cleverTapCommonEvents.bannerClicked,
                        {
                          ...pageBannerData,
                          banner_id: index,
                          banner_name: item?.category_Name,
                        },
                        ["clevertap"],
                      );
                    }}
                  >
                    <CategoryImage
                      varient="imageWithText"
                      imageSrc={item.banner_image_url}
                      label={item.category_Name}
                      alt={item.category_Name}
                      width={dimensions?.width}
                      height={dimensions?.height}
                      sizes="12vw"
                      classes="rounded-2xl"
                      priority={true}
                    />
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <div className="-mt-8 w-full overflow-auto md:pt-0">
          <ul className="scrollbar-hide flex w-full gap-12 overflow-x-auto overflow-y-hidden px-16">
            {firstHalf.map((item, index) => {
              return (
                <li key={item.category_link} className="w-[21%] flex-none">
                  <div className="flex flex-col">
                    <a
                      className="category_new_UX"
                      href={item.category_link}
                      onClick={() => {
                        trackEvent(
                          cleverTapCommonEvents.bannerClicked,
                          {
                            ...pageBannerData,
                            banner_id: index,
                            banner_name: item?.category_Name,
                          },
                          ["clevertap"],
                        );
                      }}
                    >
                      <CategoryImage
                        varient="imageWithText"
                        imageSrc={item.banner_image_url}
                        label={item.category_Name}
                        alt={item.category_Name}
                        width={dimensions?.width}
                        height={dimensions?.height}
                        customStyle={{ width: 200 }}
                        sizes="12vw"
                        classes="rounded-2xl"
                        isTextTruncated={false}
                        priority={true}
                      />
                    </a>
                    <div className="pt-10">
                      {secondHalf[index] && (
                        <a
                          className="pt-9"
                          href={secondHalf[index].category_link}
                          onClick={() => {
                            trackEvent(
                              cleverTapCommonEvents.bannerClicked,
                              {
                                ...pageBannerData,
                                banner_id: index,
                                banner_name: item?.category_Name,
                              },
                              ["clevertap"],
                            );
                          }}
                        >
                          <CategoryImage
                            varient="imageWithText"
                            imageSrc={secondHalf[index].banner_image_url}
                            label={secondHalf[index].category_Name}
                            alt={secondHalf[index].category_Name}
                            width={dimensions?.width}
                            height={dimensions?.height}
                            customStyle={{ width: 200 }}
                            sizes="12vw"
                            classes="rounded-2xl"
                            isTextTruncated={false}
                            priority={true}
                          />
                        </a>
                      )}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}

DoubleDeckerList.propTypes = {
  categoryDoubleDeckerItems: PropTypes.array.isRequired,
  classes: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  dimensions: PropTypes.object.isRequired,
  showOnDesktop: PropTypes.number.isRequired,
};

export default DoubleDeckerList;
