/* eslint-disable react/jsx-handler-names */
"use client";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { COUNTRIES } from "../../constants/common/common";
import {
  HOME_CATEGORY_OPTIONS,
  HOME_OFFERS_OPTIONS,
  HOME_SQUARE_BANNER_OPTIONS,
  OLD_HOME_CATEGORY_OPTIONS,
} from "../../constants/common/splideOptions";
import { setCatalogId, setIsMobile } from "../../store/slices/common/appConfig";
import DesktopBannerCarousel from "./homeComponents/desktopBannerCarousel";
import DoubleDeckerList from "./homeComponents/doubleDeckerList";
import HeroBannerSlider from "./homeComponents/heroBanner";
import useAnalytics from "../../analytics/useAnalytics";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../analytics/clevertapConstants";
import { PageTypes, setPageType } from "../../utils/fnpPageType";
import MobileSearch from "../../components/organisms/mobileSearch";
import useCommonOmnitureSetup from "../../customHooks/omniture/useCommonOmnitureSetup";
import SendGiftAbroadComponent from "./homeComponents/sendGiftAbroadComponent";
import HometabionComponent from "./homeComponents/hometabionComponent";
import GiftAssistance from "./homeComponents/giftAssistance";
import { useABTestingView } from "../../customHooks/clevertap/useABTestingView";
import { restrictAutoScrollingOnLoad } from "../../utils/common";

const CommonConditionalComponents = dynamic(() => import("../../components/molecules/commonConditionalComponents"), {
  ssr: false,
});
const GiftFinderSection = dynamic(() => import("./homeComponents/giftFinderSection"));
const CategorySection = dynamic(() => import("./homeComponents/categorySection"));
const CategoryCarousel = dynamic(() => import("./homeComponents/categoryCarousel"));
const CarouselOrScrollSection = dynamic(() => import("./homeComponents/CarouselOrScrollSection"));
const DownloadApp = dynamic(() => import("./homeComponents/downloadApp"));
const OccasionSection = dynamic(() => import("./homeComponents/occasionSection"));
const HomeWidgetSection = dynamic(() => import("./homeComponents/HomeWidgetSection"));
const NumOneBrandSection = dynamic(() => import("./homeComponents/numOneBrandSection"));
const OneTapLogin = dynamic(() => import("../../components/organisms/googleOneTapLogin"));
const SaleAssist = dynamic(() => import("./homeComponents/saleAssist"));
const SameDayTimer = dynamic(() => import("./homeComponents/sameDayTimer"));
const CelebSection = dynamic(() => import("./homeComponents/celebSection"));

/**
 * Renders different components based on the type of item.
 *
 * @param {Object} item - The item object containing details for rendering.
 * @param {boolean} isMobile - Flag indicating if the device is mobile.
 * @param {Object} timeData - Data for same day delivery timing.
 * @returns {JSX.Element|null} - The rendered component or null if no match.
 */
const renderComponent = (item, isMobile, timeData, isGiftAssist) => {
  // eslint-disable-next-line camelcase
  const { id, type, list, category_Name, dimensions, showOnDesktop } = item;

  if (id.startsWith("n")) {
    switch (type) {
      case "heroBannerCarouselDesktop":
        if (!isMobile)
          return (
            <DesktopBannerCarousel
              key={id}
              data={list}
              dimensions={item?.dimensions}
              sliderOptions={item?.sliderOptions}
            />
          );
        break;
      case "categoryDoubleDecker":
        return (
          <DoubleDeckerList
            key={id}
            categoryDoubleDeckerItems={list}
            classes=""
            isMobile={isMobile}
            dimensions={dimensions}
            showOnDesktop={showOnDesktop}
          />
        );
      case "heroBannerCarousel":
        if (isMobile)
          return <HeroBannerSlider isMobile={isMobile} bannersList={list} key={id} dimensions={item?.dimensions} />;
        break;
      case "tailoredForYourOccasions":
        // eslint-disable-next-line camelcase
        return <HometabionComponent key={id} isMobile={isMobile} fetchData={list} title={category_Name} />;
      case "giftFinder":
        if (isGiftAssist !== "true") {
          return <GiftFinderSection key={id} data={item} isMobile={isMobile} />;
        }
        return;
      case "category":
        return <CategorySection key={id} data={item} dimensions={item?.dimensions} isMobile={isMobile} />;
      case "offers":
        return <CategoryCarousel key={id} data={item} options={HOME_CATEGORY_OPTIONS} dimensions={item?.dimensions} />;
      case "brand":
        return (
          <CarouselOrScrollSection
            key={id}
            data={item}
            dimensions={item?.dimensions}
            isMobile={isMobile}
            slidePerPage={item?.slidePerPage}
          />
        );
      case "downloadApp":
        return <DownloadApp key={id} data={item} isMobile={isMobile} />;
      case "days":
        return <OccasionSection key={id} data={item} isMobile={isMobile} />;
      case "widget":
        return <HomeWidgetSection key={id} data={item} isMobile={isMobile} />;
      case "numOneBrandSection":
        return <NumOneBrandSection key={id} data={item} isMobile={isMobile} />;
      case "saleAssist":
        return <SaleAssist key={id} data={item} />;
      /*below case removed when New JSON will add in CMS*/
      case "sameDaydeliveryBanner":
        if (timeData?.isDeliveryTimeAvailable) {
          return (
            <SameDayTimer key={id} fetchData={item} isMobile={isMobile} cutoffTimestamp={timeData?.cutoffTimestamp} />
          );
        }
        break;

      case "storyGridPLP":
        return <CelebSection key={id} fetchData={item} isMobile={isMobile} dimensions={item?.dimensions} />;
      case "horizontalCategorySmall":
      case "newHorizontalCategory":
        return (
          <CategoryCarousel
            key={id}
            data={item}
            options={OLD_HOME_CATEGORY_OPTIONS}
            dimensions={item?.dimensions}
            isOld={true}
          />
        );
      case "internationalSection":
        return <SendGiftAbroadComponent key={id} sendGiftsAbroad={item} dimensions={item?.dimensions} />;
      case "newOfferBannersSmall":
        return <CategoryCarousel key={id} data={item} options={HOME_OFFERS_OPTIONS} dimensions={item?.dimensions} />;
      case "squareCarouselBanner":
        return (
          <CategoryCarousel key={id} data={item} options={HOME_SQUARE_BANNER_OPTIONS} dimensions={item?.dimensions} />
        );
      case "giftAssistant":
        if (isGiftAssist === "true") {
          return <GiftAssistance key={id} imgUrl={item.imgUrl} />;
        }
        return;
      default:
        return null;
    }
  }

  return null;
};

/**
 * HomePage component renders the main content of the homepage.
 * It includes various sections such as carousels, product lists, and promotional banners.
 *
 * @component
 * @param {Object} props - The component props
 * @param {Object} props.homeBannerRes - The response data for the home banners, including `quickSearchContent` and `mainContent`.
 * @param {Object} props.timeData - The API data fetched from the backend, used for same day delivery timing.
 * @param {boolean} props.isMobile - A flag indicating whether the device is mobile or not.
 * @returns {JSX.Element} - The rendered homepage content.
 */
const HomePage = ({ catalogId, homeBannerRes, timeData, isMobile }) => {
  const { mainContent } = homeBannerRes;
  const homePageBanners = JSON.parse(mainContent);
  const dispatch = useDispatch();
  const [previousPathName, setPreviousPathName] = useState("");
  const { init, trackEvent } = useAnalytics();
  const pathname = typeof window !== "undefined" && window.location.pathname ? window.location.pathname : "";
  const completePathName = typeof window !== "undefined" && window.location.href ? window.location.href : "";
  const { loginDetail } = useSelector(
    (state) => ({
      loginDetail: state?.fus?.loginDetail,
    }),
    shallowEqual,
  );
  const isGiftAssistant = useABTestingView("giftAssistant");

  useCommonOmnitureSetup();

  const homePageViewedData = {
    complete_url: completePathName,
    clean_url: pathname,
    internal_source_type: previousPathName,
    internal_source: previousPathName,
    page_name: cleverTapCommonWords.HOME,
    page_type: cleverTapCommonWords.HOME,
    login_status: loginDetail?.loggedin,
    is_international: catalogId !== COUNTRIES.INDIA,
  };
  useEffect(() => {
    dispatch(setIsMobile(isMobile));
    dispatch(setCatalogId(catalogId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  useEffect(() => {
    restrictAutoScrollingOnLoad();
    /**
     * set fnp page type for home page
     */
    setPageType(PageTypes.HOME);
    init();
  }, []);

  useEffect(() => {
    const { referrer } = document;
    if (referrer) {
      setPreviousPathName(new URL(referrer).pathname);
    }
    setTimeout(() => {
      if (loginDetail?.loggedin !== undefined) {
        trackEvent(cleverTapCommonEvents.homePageViewed, homePageViewedData, ["clevertap"]);
      }
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDetail?.loggedin]);

  return (
    <>
      <OneTapLogin />
      <div className="flex flex-col gap-24 md:gap-60" id="mainContent">
        <div className="flex bg-white-900 px-16 md:hidden md:p-0">
          <MobileSearch catalogId={catalogId} />
        </div>
        {homePageBanners?.map((item) => renderComponent(item, isMobile, timeData, isGiftAssistant))}
      </div>

      <CommonConditionalComponents isMobile={isMobile} />
    </>
  );
};
export default React.memo(HomePage);

HomePage.propTypes = {
  homeBannerRes: PropTypes.shape({
    mainContent: PropTypes.string.isRequired,
  }).isRequired,
  timeData: PropTypes.shape({
    isDeliveryTimeAvailable: PropTypes.bool,
    cutoffTimestamp: PropTypes.string,
  }),
  isMobile: PropTypes.bool.isRequired,
  catalogId: PropTypes.string.isRequired,
};
