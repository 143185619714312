import PropTypes from "prop-types";
import CustomImage from "../../../components/atoms/customImage";
import PopOver from "../../../components/atoms/popOver";
import { useState } from "react";
import { ENGATI_ENDPOINT } from "../../../constants/common/common";

/**
 * GiftAssistance component displays a tab-based interface for finding gifts and includes a section
 * for sending gifts abroad.
 * @param {Object} props - The component props.
 * @param {Object} props.data - The data for the gift finder section.
 * @returns {JSX.Element} - The rendered GiftFinderSection component.
 */
function GiftAssistance({ imgUrl }) {
  const [toogleGiftAssistant, setToogleGiftAssistant] = useState(false);
  const [animate, setAnimate] = useState(false);

  /**
   * Handler to open the Engati chat gifting assistant.
   */
  const onGiftAssistantHandler = (isPreventOutsideClick = false) => {
    if (!isPreventOutsideClick) {
      setToogleGiftAssistant(!toogleGiftAssistant);
    } else {
      setAnimate(true);
      setTimeout(() => {
        setAnimate(false);
      }, 3000);
    }
  };

  return (
    <>
      <div className="relative flex w-full cursor-pointer px-40">
        <CustomImage
          onClick={() => onGiftAssistantHandler(false)}
          src={imgUrl}
          width={100}
          height={100}
          alt="Gift Assitance"
          sizes="10vw"
          isPriority={false}
          customStyle={{ width: "100%", height: "max-content" }}
        />
      </div>
      <PopOver
        isVisible={toogleGiftAssistant}
        classes={`top-[10%] pt-14 px-16 bottom-auto overflow-y-hidden left-[19%] w-[60%] h-[calc(100%-160px)] rounded-2xl ${animate ? "animate-bounce" : ""}`}
        childrenClass="h-full"
        onTogglePopover={(value) => onGiftAssistantHandler(value)}
        isBackdrop={true}
        isShowCancelIcon={true}
        isPreventOutsideClick={true}
      >
        <iframe
          border="0"
          src={ENGATI_ENDPOINT}
          target="_parent"
          height="100%"
          name="Gift Assitant"
          width="100%"
          title="Gift Assistant"
          className="h-full w-full"
        />
      </PopOver>
    </>
  );
}

GiftAssistance.propTypes = {
  imgUrl: PropTypes.string.isRequired,
};
export default GiftAssistance;
