"use client";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Typography from "../../../components/atoms/typography";
import { Tabs } from "../../../components/molecules/tabion";
import Tab from "../../../components/molecules/tabion/tab";
import TabList from "../../../components/molecules/tabion/tabList";
import TabPanel from "../../../components/molecules/tabion/tabPanel";
import CustomImage from "../../../components/atoms/customImage";
import { getRecentViewedByYouAPI } from "../../../api/pdpApi";
import { PageTypes } from "../../../utils/fnpPageType";
import Skeleton from "../../../components/molecules/skeletons/skeleton";
import Widget from "../../../components/organisms/widget";

/**
 * HometabionComponent displays a tabbed interface with various product carousels
 * and a component for sending gifts abroad.
 *
 * @component
 * @param {Object} props - The component props
 * @param {Array} props.fetchData - The data for the product tiles
 * @param {Object} props.title - section title
 * @returns {JSX.Element}
 */
function HometabionComponent({ fetchData, title, isMobile }) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [products, setProducts] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  const recentViewedByYouResponse = useCallback(async (productsList) => {
    const data = await getRecentViewedByYouAPI(productsList);
    if (data?.responseStatus === "success") {
      setProducts(data?.data?.productResults);
      setShowLoader(false);
    }
  }, []);

  useEffect(() => {
    if (fetchData.length > 0) {
      recentViewedByYouResponse(fetchData[selectedTab]?.products);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const onTabChange = useCallback((evt) => {
    setShowLoader(true);
    setSelectedTab(evt);
  }, []);

  const tabPanels = useMemo(() => {
    return fetchData?.map((list, index) => (
      <TabPanel
        selected={selectedTab}
        index={index}
        key={index}
        className="md:pt-21 relative bg-gradient-to-b from-fnp-700 to-white-900 px-0 pb-0 pt-16 md:px-16 md:pb-0 xxl:pt-22"
      >
        {showLoader ? (
          <Skeleton
            variant="rounded"
            customStyle={{
              width: "100%",
              height: isMobile ? 200 : 350,
              margin: "12px",
            }}
            count={isMobile ? 2 : 4}
            gap="10px"
            alignment="center"
          />
        ) : (
          <>
            <Widget
              productsList={products}
              isMobile={isMobile}
              isShowEarliestDate={false}
              isHideSavedPercent={true}
              pageType={PageTypes?.HOME}
              titleStyle="pl-0"
            />
            <div className="mx-16 block text-center text-12 font-500 text-fnp-600 sm:text-16 md:mt-30">
              <a
                href={list.category_link}
                className="inline-flex w-[100%] items-center justify-center rounded-8 border-1 border-fnp bg-fnp-50 px-16 py-8 text-12 font-600 md:w-[360px] md:text-16"
              >
                View All {list.category_Name} Gifts{" "}
                <CustomImage
                  alt="right arrow"
                  width={isMobile ? 12 : 16}
                  height={isMobile ? 12 : 16}
                  sizes="10vw"
                  src="/icons/arrow-right-fnp.svg"
                />
              </a>
            </div>
          </>
        )}
      </TabPanel>
    ));
  }, [fetchData, products, selectedTab, isMobile, showLoader]);
  return (
    <div className="px-0 sm:px-40">
      {title && (
        <div className="justify-left mb-12 flex px-16 md:px-0">
          <Typography variant="" Tag="p" classes="text-16 font-600 lg:text-24 xxl:text-32">
            {title}
          </Typography>
        </div>
      )}
      <div className="w-full">
        <Tabs onClick={onTabChange}>
          <TabList
            selected={selectedTab}
            onChange={onTabChange}
            onTabSelected={onTabChange}
            tabCount={fetchData.length}
            isBottomRound={true}
          >
            {fetchData?.map((list, index) => {
              return (
                <Tab bgStyleClass="relative w-fit p-16 rounded-t-xl sm:flex-1" key={index}>
                  <div className="flex w-full flex-col items-center justify-center gap-8">
                    <CustomImage
                      src={list.banner_image_url}
                      alt={list.category_Name}
                      width={24}
                      height={24}
                      sizes="10vw"
                      isPriority={false}
                      object-fit="cover"
                      customStyle={{
                        width: "24px",
                        height: "24px",
                      }}
                    />
                    <Typography
                      variant=""
                      Tag="p"
                      classes={`text-12 ${selectedTab === index ? "font-600 text-fnp-400" : "font-400 text-grey-400"} text-nowrap xl:text-16 xxl:text-18`}
                    >
                      {list.category_Name}
                    </Typography>
                  </div>
                </Tab>
              );
            })}
          </TabList>

          {tabPanels}
        </Tabs>
      </div>
    </div>
  );
}

HometabionComponent.propTypes = {
  fetchData: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
export default React.memo(HometabionComponent);
