import { PropTypes } from "prop-types";
import CustomImage from "../../atoms/customImage/index";

/**
 * Description placeholder
 * @date 14/03/2024 - 16:52:21
 * CategoryImage component displays an image with optional text or stripe.
 * @param {{ label: any; varient: any; imageSrc: any; width: any; height: any; priority: any; sizes: any; alt: any; textColor: any; stripColor: any; }} param
 * @param {string} param.label - The text label displayed alongside the image.
 * @param {string} param.varient - The variant of the CategoryImage ("imageWithText", "imageWithStripe").
 * @param {string} param.imageSrc- The source URL of the image.
 * @param {number} param.width - The width of the image.
 * @param {number} param.height - The height of the image.
 * @param {boolean} param.priority - Whether the image should be loaded with priority.
 * @param {string} param.sizes - The sizes attribute for the image.
 * @param {string} param.alt - The alt attribute for the image.
 * @param {string} param.textColor - The color of the text (applies when varient is "imageWithStripe").
 * @param {string} param.stripColor - The background color of the stripe (applies when varient is "imageWithStripe").
 * @returns {JSX.Element} The CategoryImage component.
 */
const CategoryImage = ({
  label,
  varient,
  imageSrc,
  width,
  height,
  priority = false,
  sizes,
  alt,
  textColor,
  stripColor,
  classes = "rounded-xl",
  customStyle,
  isTextTruncated = true,
}) => {
  const textStyle = {
    color: textColor,
  };

  const backgroundColorStyle = {
    backgroundColor: stripColor,
  };

  return (
    <div
      className={`relative flex flex-col ${varient === "imageWithStripe" ? `w-full overflow-hidden  ${classes}` : ""}`}
    >
      <div className={`relative ${varient === "imageWithText" ? `w-full overflow-hidden ${classes}` : ""}`}>
        <CustomImage
          src={imageSrc}
          alt={alt}
          width={width}
          height={height}
          isPriority={priority}
          sizes={sizes}
          customStyle={customStyle}
        />
      </div>
      {varient === "imageWithText" && (
        <div
          className={`mt-8 overflow-hidden ${isTextTruncated ? "text-ellipsis whitespace-nowrap" : "h-[34px] leading-tight"}  xmll:text-18 text-center text-12 font-500 capitalize not-italic lg:text-14`}
          style={{ ...textStyle, ...backgroundColorStyle }}
        >
          {label}
        </div>
      )}
    </div>
  );
};

CategoryImage.propTypes = {
  label: PropTypes.string,
  varient: PropTypes.string,
  imageSrc: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  // eslint-disable-next-line react/boolean-prop-naming
  priority: PropTypes.bool,
  sizes: PropTypes.string,
  alt: PropTypes.string,
  textColor: PropTypes.string,
  stripColor: PropTypes.string,
  classes: PropTypes.string,
  customStyle: PropTypes.object,
  isTextTruncated: PropTypes.bool,
};

export default CategoryImage;
